import React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../components/Layout';
import { Meta } from '../components/Meta';
import { Main } from '../components/Main';
import { Content } from '../components/Content';

export default () => (
  <Layout>
    <Meta title="O štúdiu" />
    <Main>
      <h1>O štúdiu</h1>
      <Content>
        <p>
          Kuchyňa je miesto, kde trávite veľa času so svojou rodinou. Preto by mala byť presne podľa Vašich predstáv,
          aby lahodila oku a navodzovala príjemnú atmosféru domova. Ak máte záujem o kúpu novej kuchynskej linky <Link to="/kontakt">ozvite sa nám</Link>. Štúdio Finlux bolo založené v roku 1991 a
          spokojnosť zákazníkov a dlhoročná tradícia prispela k rozvoju našej firmy. My Vám kuchyňu zariadime do
          posledných detailov.
        </p>
        <p>
          Ponúkame výrobu kuchynských liniek na mieru presne podľa požiadaviek zákazníka. Podľa vymeraných parametrov
          Vám na počítači urobíme 3D projekt, ktorý bude predstavovať finálnu verziu Vašej kuchyne. Vy sa tak už vopred
          môžete pozrieť ako Vaša kuchyňa bude vyzerať. Potom je už iba potrebné vybrať materiál a farbu. Farbu si
          môžete vybrať zo vzorkovníkov s bohatým výberom. Ďalším podstatným výberom sú dvierka. Ponúkame MDF –
          fóliované a pre náročnejších zákazníkom ponúkame slovenský masív – Dub, Buk a Jelša.
        </p>
        <p>
          Ale pre tých najnáročnejších ponúkame kvalitný masív dovážaný priamo z Talianska. V našich kuchyniach nájdete
          iba kvalitné značkové kovanie BLUM. Ku kuchyni patria samozrejme aj spotrebiče.
        </p>
        <p>
          Kuchynské štúdio Finlux Vám ponúka spotrebiče svetových značiek ako napríklad WHIRLPOOL, GORENJE, MORA,
          ROSIERES a mnoho ďalších. Určite si vyberiete aj z našej ponuky drezov z nemeckej firmy BLANCO, alebo
          švajčiarskej firmy FRANKE. U nás zakúpené spotrebiče Vám pri kúpe kuchynskej linky odvezieme zdarma. Vašu
          kuchyňu Vám namontujú profesionáli s dlhoročnými skúsenosťami.
          Preto neváhajte a <Link to="/kontakt">kontaktujte nás</Link>.
        </p>
      </Content>
    </Main>
  </Layout>
);
